import "./App.css";
import Footer from "./components/Footer";
import { IoLeafOutline } from "react-icons/io5";
import { PiSolarPanelLight } from "react-icons/pi";
import turunc1 from "./assets/images/turunc_1.jpeg";
import turunc2 from "./assets/images/turunc_2.jpeg";
import portakal1 from "./assets/images/portakal_1.jpg";
import portakal2 from "./assets/images/portakal_2.jpeg";
import sereflikochisar from "./assets/images/sereflikochisar_1.jpeg";
import sefakoy from "./assets/images/sefakoy_1.jpg";
import ayas from "./assets/images/ayas_1.jpeg";
import bilecik1 from "./assets/images/bilecik_1.jpg";
import aqua from "./assets/images/salur.jpg";
import degirmenci from "./assets/images/degirmenci.jpg";
import polatli from "./assets/images/bilecik_2.jpg";
import { GiSolarPower } from "react-icons/gi";
import ScrollToTop from "react-scroll-to-top";
import { TfiArrowCircleUp } from "react-icons/tfi";

function App() {
   document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
            e.preventDefault();
            document.querySelector(this.getAttribute('href')).scrollIntoView({
               behavior: 'smooth',
               scrollPaddingTop: '6rem'
            });
      });
   });

   document.addEventListener('scroll', function () {
      // navbar sticky
      const navbar = document.getElementById('navbar');
      const menu = document.getElementById('menu');
      if (navbar) {
         if (window.scrollY > 96) {
            navbar.classList.add('bg-opacity-60', 'bg-black', 'text-white');
         } else {
            navbar.classList.remove('bg-opacity-60', 'bg-black', 'text-white');
         }
      }

      if (menu) {
         if (window.scrollY > 96) {
            menu.classList.add('bg-opacity-60', 'bg-black', 'text-white');
         } else {
            menu.classList.remove('bg-opacity-60', 'bg-black', 'text-white');
         }
      }
   });

   const panels = [
      {
         name: 'Turunç 1',
         desc: 'Nevşehir Acıgöl bölgesinde açık alanda konumlandırılmış paneller.',
         image: turunc1,
         power: 688
      },
      {
         name: 'Turunç 2',
         desc: 'Nevşehir Acıgöl bölgesinde açık alanda konumlandırılmış paneller.',
         image: turunc2,
         power: 688
      },
      {
         name: 'Portakal 1',
         desc: 'Nevşehir Acıgöl bölgesinde açık alanda konumlandırılmış paneller.',
         image: portakal1,
         power: 688
      },
      {
         name: 'Portakal 2',
         desc: 'Nevşehir Acıgöl bölgesinde açık alanda konumlandırılmış paneller.',
         image: portakal2,
         power: 688
      },
      {
         name: 'Şereflikoçhisar',
         desc: `Ankara Şereflikoçhisar'da açık alanda konumlandırılmış paneller.`,
         image: sereflikochisar,
         power: 1230
      },
      {
         name: 'Küçükçekmece',
         desc: 'İstanbul bölgesinde merkez ofis çatısında konumlandırılmış paneller.',
         image: sefakoy,
         power: 130
      },
      {
         name: 'Ayaş',
         desc: 'Ankara Ayaş bölgesinde açık alanda konumlandırılmış treking sistem.',
         image: ayas,
         power: 600
      },
      {
         name: 'Bilecik',
         desc: 'Bilecik Bitez bölgesinde açık alanda konumlandırılmış paneller.',
         image: bilecik1,
         power: 600
      },
      {
         name: 'Aqua',
         desc: 'Gaziantep Nizip bölgesinde açık alanda konumlandırılmış paneller.',
         image: aqua,
         power: 1186
      },
      {
         name: 'Demirci',
         desc: 'Manisa Demirci bölgesinde açık alanda konumlandırılmış paneller.',
         image: degirmenci,
         power: 2880
      },
      {
         name: 'Polatlı',
         desc: 'Ankara Polatlı bölgesinde açık alanda konumlandırılmış paneller.',
         image: polatli,
         power: 1180
      }
   ];

   return (
      <div className="w-full flex flex-col bg-white">
         <ScrollToTop smooth color="#0891b2" className="flex justify-center items-center" style={{backgroundColor: '#ffffff', borderRadius: '50px'}} component={<TfiArrowCircleUp size={64} />} />
         {/* header image */}
         <div className="w-full h-screen bg-cover md:bg-center bg-bottom bg-no-repeat bg-header-image">
            <div className="container mx-auto h-full flex flex-col pt-64 ps-4 md:ps-36">
               <p className="text-green-500 text-2xl font-bold">Güneş Enerji Sistemleri.</p>
               <h1 className="text-green-600 text-6xl font-bold">Temiz Enerjinin Gücü</h1>
            </div>
         </div>
         {/* about */}
         <div className="w-full bg-white" id="hakkimizda">
            <div className="w-full h-full grid md:grid-cols-2 grid-cols-1 py-16">
               <div className="md:w-full h-full  flex flex-col items-center justify-center">
                  <p className="text-green-600 md:text-4xl text-2xl poppins-bold">Hakkımızda</p>
                  <p className="text-cyan-600 text-lg my-4 md:text-justify text-left md:px-16 px-4">Beyoğlu Güneş Enerji Sistemleri A.Ş. olarak, Türkiye'nin enerji ihtiyacını karşılamakta yenilenebilir enerji kaynaklarının öneminin farkındayız. Bu bilinçle, güneş enerjisi alanında faaliyet göstererek ülkemizin enerji dönüşümüne katkıda bulunmayı hedefliyoruz. Şirketimiz, 11 adet güneş enerji santrali ile toplam 9632.6 kWp kurulu güce ulaşmış bulunmaktadır. Bu santrallerimiz aracılığıyla hem çevreye duyarlı bir üretim gerçekleştiriyor hem de ülke ekonomisine katkı sağlıyoruz. Güneş enerjisi projelerinin geliştirilmesi, üretim tesislerinin kurulması ve enerji ticareti alanlarında sunduğumuz hizmetlerle sektördeki yerimizi güçlendirmeye devam ediyoruz.</p>
               </div>
               <div className="md:w-full h-[450px] bg-about-image bg-cover bg-center">
               </div>
            </div>
         </div>
         {/* fixed image */}
         <div className="w-full h-[600px] bg-section1-image bg-cover md:bg-fixed md:bg-center bg-no-repeat bg-inherit bg-right-bottom">
            <div className="mx-auto h-full flex flex-col justify-center items-center bg-black bg-opacity-20 px-8 text-center">
               <h1 className="text-cyan-600 md:text-6xl text-4xl poppins-medium">Doğadan Enerji</h1>
               <p className="text-green-500 md:text-2xl text-xl mt-4">Sürdürülebilir bir dünya için; Doğal enerji, temiz gelecek.</p>
               <div className="flex gap-4 mt-4">
                  <IoLeafOutline className="text-green-500 text-4xl" />
                  <IoLeafOutline className="text-green-500 text-4xl" />
                  <IoLeafOutline className="text-green-500 text-4xl" />
               </div>
            </div>
         </div>
         {/* services */}
         <div className="w-full bg-white py-16" id="hizmetlerimiz">
            <div className="container mx-auto h-full flex flex-col justify-center items-center">
               <h1 className="text-green-600 md:text-4xl text-2xl poppins-bold">Enerji Üretim Tesislerimiz</h1>
               <div className="w-full grid xl:grid-cols-4 md:grid-cols-3 grid-cols-1 justify-center items-center gap-4 mt-8">
                  {panels.map((panel, index) => (
                     <div key={index} id="card" className="flex flex-col justify-center items-center px-2">
                        <img src={panel.image} alt="panel1" className="md:w-[300px] w-full h-[200px] object-cover" />
                        <p className="text-green-600 text-sm mt-2 poppins-bold">{panel.name}</p>
                        <p className="text-green-600 text-sm mt-2 poppins-medium text-center">{panel.desc}</p>
                        <p className="text-cyan-600 text-sm mt-2 poppins-bold">Kurulu Güç: {panel.power} kWp</p>
                        <hr className="md:hidden w-full border-green-600 border-2 mt-2" />
                     </div>
                  ))}
               </div>
               <div className="w-full grid md:grid-cols-2 grid-cols-1 justify-center items-center mt-16">
                  <div className="flex flex-col px-16 justify-center items-center">
                     <p className="text-green-600 md:text-2xl text-xl text-center poppins-bold">Yerel Sertifika Standartlarında ve En Yüksek Güvenlik Gereksinimli {panels.length} Bölgede Kurulu Paneller</p>
                     <GiSolarPower className="text-green-600 md:text-[20rem] text-[15rem]" />
                  </div>
                  <div className="flex flex-row px-16 justify-center items-center">
                     <div className="flex flex-col justify-center items-center gap-4 md:mt-0 mt-6">
                        <p className="text-cyan-600 md:text-4xl text-4xl md:mt-0 mt-4 text-center poppins-bold md:-mb-10 -mb-6">Toplam</p>
                        <p className="text-white stroke-green-600 md:text-[10rem] text-[7rem] poppins-bold">
                           <span style={{ WebkitTextStroke: '4px green', stroke: 'green', strokeWidth: '2px' }}>
                              {parseInt(panels.reduce((acc, panel) => acc + panel.power, 0))}
                           </span>
                        </p>
                        <hr className="w-2/3 border-green-600 border-2 -mt-10 mb-4" />
                        <p className="text-cyan-600 text-2xl text-center poppins-bold md:-mt-0 -mt-2">kWp Kurulu Güç</p>
                        <p className="text-green-600 text-sm poppins-bold">Yılda 17 milyon kWh enerji üreterek 8,500 ton CO₂ tasarrufu sağlıyoruz.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {/* fixed image */}
         <div className="w-full h-[600px] bg-section1-image bg-cover md:bg-fixed md:bg-center bg-no-repeat bg-inherit bg-bottom">
            <div className="mx-auto h-full flex flex-col justify-center items-center bg-black bg-opacity-20 px-8 text-center">
               <h1 className="text-cyan-600 md:text-6xl text-4xl poppins-medium">Enerjiye Yeni Bir Bakış</h1>
               <p className="text-green-500 md:text-2xl text-xl mt-4">Enerjinizi Doğadan Alın.</p>
               <div className="flex gap-4 mt-4">
                  <PiSolarPanelLight className="text-green-500 text-4xl" />
                  <PiSolarPanelLight className="text-green-500 text-4xl" />
                  <PiSolarPanelLight className="text-green-500 text-4xl" />
               </div>
            </div>
         </div>
         {/* contact */}
         <div className="w-full bg-white" id="iletisim">
            <div className="mx-auto h-full flex flex-col justify-center items-center">
               <div className="flex flex-col w-full h-full grayscale hover:grayscale-0 transition-all pt-8">
                  <iframe title="harita" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12040.19872659492!2d28.805287!3d41.024169!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa45d96eaa5e1%3A0x12d9994ac0b006ff!2zxLBuw7Zuw7wsIE9rYXkgU2suIE5vOjE3LCAzNDI5NSBLw7zDp8O8a8OnZWttZWNlL8Swc3RhbmJ1bA!5e0!3m2!1str!2str!4v1722518116193!5m2!1str!2str" width={'100%'} height={600} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
               </div>
            </div>
         </div>
         {/* footer */}
         <Footer />
      </div>
   );
}

export default App;
