import React from 'react'
import { CgMail } from 'react-icons/cg'
import { FaWhatsapp } from 'react-icons/fa'

export default function Footer() {
    return (
        <div className='w-full flex flex-col py-2 bg-cyan-700'>
            <div className='container mx-auto grid md:grid-cols-5 grid-cols-1 gap-6'>
                <div className='w-full flex flex-col justify-center items-center'>
                </div>
                <div className='w-full flex flex-col md:items-normal items-center md:mt-0 mt-8'>
                    <h1 className='text-white font-bold text-lg'>Beyoğlu GES</h1>
                    <p className='text-gray-300 text-sm -mt-1'>Güneş Enerjisi Sistemleri</p>
                    <p className='text-gray-300 text-sm mt-4'>
                        <CgMail className='inline-block mr-2' />
                        <a href='mailto:info@beyogluges.com'>info@beyogluges.com</a>
                    </p>
                    <p className='text-gray-300 text-sm mt-2'>
                        <FaWhatsapp className='inline-block mr-2' />
                        <a href='whatsapp://send?abid=+905335847125&text=Merhaba' >+90 533 504 71 25</a>
                    </p>
                </div>
                <div className='w-full flex flex-col md:items-normal items-center md:mt-0 mt-8'>
                    <h1 className='text-white font-bold text-lg'>Adres</h1>
                    <p className='text-gray-300 text-sm md:text-start text-center'>Muammer Aksoy Cad. Okay Sk. No:17 Sefaköy / Küçükçekmece / İSTANBUL</p>
                </div>
                <div className='w-full md:flex flex-col md:items-normal items-center md:mt-0 mt-8 hidden'>
                    <h1 className='text-white font-bold text-lg'>Menü</h1>
                    <a href='/' className='text-gray-300 text-sm'>Anasayfa</a>
                    <a href='#hakkimizda' className='text-gray-300 text-sm'>Hakkımızda</a>
                    <a href='#hizmetlerimiz' className='text-gray-300 text-sm'>Hizmetlerimiz</a>
                    <a href='#iletisim' className='text-gray-300 text-sm'>İletişim</a>
                </div>
                <div className='w-full flex flex-col justify-center items-center'>
                </div>
            </div>
            <div className='container mx-auto grid grid-cols-1 justify-center items-center mt-8'>
                <p className='text-center text-gray-300 text-xs'>Beyoğlu GES &copy; {new Date().getFullYear()}. Tüm Hakları Saklıdır.</p>
                <p className='text-center text-gray-300 text-xs italic'>Designed by <a href='https://kadircorbacioglu.com.tr' target='_blank' rel='noreferrer'><span className=''>Kadir Ç</span></a></p>
            </div>
        </div>
    )
}
